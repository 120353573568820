const { siteUrl } = require('./config');

/**
 * Returns path for a page in the pagination
 *
 * @param {string} basePath The base path
 * @param {number} page Current page
 * @return {string}
 */
exports.paginationPagePath = (basePath, page) => (page === 1 ? basePath : `${basePath.replace(/\/$/, '')}/page/${page}`);

/**
 * Returns home URL with the optional path argument appended.
 *
 * @param {string} [path="/"] Path relative to the home URL.
 */
exports.homeUrl = (path = '/') => `${siteUrl}${path}`;

/**
 * Converts an array of pages to a map of path -> page
 *
 * @param {Array<Object>} allPages
 * @return {Object<string, Object>} map of path -> page
 */
exports.pagesToMap = (allPages) => allPages.reduce((map, page) => {
  // Avoid spread-reduce anti-pattern (https://www.richsnapp.com/blog/2019/06-09-reduce-spread-anti-pattern)
  // eslint-disable-next-line no-param-reassign
  map[page.path] = page;
  return map;
}, {});

/**
 * Returns function to map an edge to a search entry
 * @param {string} contentType - name of content
 * @param {string} titleFieldKey - key for title field
 * @param {string} excerptFieldKey - key for excerpt field
 * @param {boolean} includeImages - true to include images
 * @param {boolean} includeContent - true to include content
 * @return {function(*): {path: *, type: *, title: *, excerpt: *, image: *, content: *}}
 */
exports.edgeToSearchContentType = (contentType, {
  titleFieldKey = 'title',
  excerptFieldKey = 'excerpt',
  includeImages = false,
  includeContent = false,
} = {}) => (edge) => {
  const searchEntry = {
    path: edge.node.path,
    type: contentType,
    title: edge.node[titleFieldKey],
    excerpt: edge.node[excerptFieldKey],
  };

  if (includeImages) {
    searchEntry.image = edge.node.image && edge.node.image.localFile
      ? edge.node.image.localFile.childImageSharp.fixed
      : null;
  }

  if (includeContent) {
    searchEntry.content = edge.node.content || '';
  }

  return searchEntry;
};
