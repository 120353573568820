// @flow
import React from 'react';
import styled from 'styled-components';

import Link from './Link';
import { paginationPagePath } from '../../utils';
import { generatePagination } from '../utils';
import AngleLeftIcon from '../images/angle-left.svg';
import AngleRightIcon from '../images/angle-right.svg';

const PageLink = styled(Link)`
  --underline-width: 0;
  display: inline-block;
  padding: 2px 12px 0;
  color: ${(props) => (props['aria-current'] ? '#26985f' : 'var(--color-link)')} !important;

  &:focus,
  &:hover {
    --underline-width: 2px;
  }
`;

const NavButton = styled(PageLink)`
  box-shadow: none !important;
`;

const NavButtonDisabled = styled.span`
  display: inline-block;
  padding: 6px 12px;
  color: #cdcdcd;
`;

const Dots = styled.span`
  display: inline-block;
  padding: 6px;
  color: #cdcdcd;
`;

const Wrapper = styled.div`
  display: flex;
  margin: 65px 0;
  align-items: center;
  justify-content: center;
  font-size: 18px;
`;

type Props = {
  basePath: string,
  numPages: number,
  currentPage: number,
  className?: string,
};

const defaultProps = {
  className: '',
};

const Pagination = ({
  basePath,
  numPages,
  currentPage,
  className,
}: Props) => {
  const pages = generatePagination(currentPage, numPages);

  const prevButtonMarkup = currentPage === 1 ? (
    <NavButtonDisabled>
      <AngleLeftIcon />
    </NavButtonDisabled>
  ) : (
    <NavButton rel="prev" to={paginationPagePath(basePath, currentPage - 1)}>
      <AngleLeftIcon />
    </NavButton>
  );

  const nextButtonMarkup = currentPage === numPages ? (
    <NavButtonDisabled>
      <AngleRightIcon />
    </NavButtonDisabled>
  ) : (
    <NavButton rel="next" to={paginationPagePath(basePath, currentPage + 1)}>
      <AngleRightIcon />
    </NavButton>
  );

  return (
    <Wrapper className={className}>
      {prevButtonMarkup}
      {pages.map((page, index) => (page === 'DOTS' ? (
        // eslint-disable-next-line react/no-array-index-key
        <Dots key={`dots${index}`}>&hellip;</Dots>
      ) : (
        <PageLink
          key={page}
          to={paginationPagePath(basePath, page)}
          aria-current={page === currentPage ? 'page' : null}
        >
          {page}
        </PageLink>
      )))}
      {nextButtonMarkup}
    </Wrapper>
  );
};

Pagination.defaultProps = defaultProps;

export default Pagination;
