// @flow
import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import classnames from 'classnames';

import Link from './Link';
import CommentsIcon from '../images/comments.svg';
import PostTitle from './PostTitle';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 46px;
  padding: 14px 13px 20px;
  background: ${(props) => (props.isGuide ? '#ebf5f1' : '#fff')};
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.15, 0.53, 0.35, 1);

  &:hover {
    transform: scale(1.03);
    box-shadow: ${(props) => (props.isGuide
    ? '0px 0px 13px 2px rgba(0, 0, 0, 0.13)'
    : '0px 0px 13px 2px rgba(0, 0, 0, 0.08)')};
    z-index: 1;
  }

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
`;

const StyledImg = styled(Img)`
  margin-bottom: 18px;
  border-radius: 4px;

  * {
    vertical-align: bottom; /* removes extra space from image bottom */
  }
`;

const Title = styled(PostTitle)`
  font-size: 20px;
  line-height: 28px;
  opacity: 0.9;
`;

const Excerpt = styled.div`
  font-size: 16px;

  p:last-of-type {
    margin-bottom: 0;
  }
`;

const Comments = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  font-size: 14px;
  color: #4a4a4a;

  svg {
    margin-right: 7px;
  }
`;

const Tags = styled.div`
  margin-top: 0.8rem;
`;

const Tag = styled(Link)`
  font-size: 13px;
  color: #8e8e8e;

  & + & {
    margin-left: 10px;
  }
`;

export type PostListItemType = {
  title: string,
  excerpt: string,
  path: string,
  type: string,
  featuredImage?: {
    alt: string,
    localFile?: {
      childImageSharp: {
        fluid: any,
      }
    },
  },
  customMeta: {
    commentCount: number,
  },
  tags?: Array<{
    path: string,
    name: string,
  }>,
};

type Props = {
  item: PostListItemType,
  className?: string,
};

const defaultProps = {
  className: '',
};

const PostListItem = ({
  item: {
    title,
    excerpt,
    path,
    type,
    featuredImage,
    customMeta: { commentCount },
    tags,
  },
  className,
}: Props) => {
  const isGuide = type === 'guide';
  const isAlternative = type === 'alternative';

  return (
    <Wrapper className={classnames('hentry', className)} isGuide={isGuide}>
      <Link to={path} rel="bookmark">
        {featuredImage && featuredImage.localFile && (
          <StyledImg
            fluid={{ ...featuredImage.localFile.childImageSharp.fluid, aspectRatio: 1.5 }}
            alt={featuredImage.alt}
            imgStyle={{
              objectPosition: isAlternative ? 'top center' : 'center center',
            }}
          />
        )}
        {/* using "as" prop instead of "$as" would swap the PostTitle component
          with the component/element provided in this prop */}
        <Title title={title} isGuide={isGuide} $as="h3" />
        {/* eslint-disable-next-line react/no-danger */}
        <Excerpt className="entry-summary" dangerouslySetInnerHTML={{ __html: excerpt }} />
      </Link>
      <Comments>
        <CommentsIcon />
        {commentCount === 1 ? '1 response' : `${commentCount} responses`}
      </Comments>
      {tags && tags.length > 0 && (
        <Tags>
          {tags.map((tag) => (<Tag key={tag.path} rel="tag" to={tag.path}>{tag.name}</Tag>))}
        </Tags>
      )}
    </Wrapper>
  );
};

PostListItem.defaultProps = defaultProps;

export default PostListItem;
