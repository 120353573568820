// @flow
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Helmet from 'react-helmet';

import type { Meta } from '../types';
import { normalizeMeta } from '../utils';

type Props = {
  data: {
    homepage: {
      yoastMeta: Meta[],
    },
  },
};

const allowedPropertyList = ['og:site_name', 'og:image', 'og:image:secure_url', 'og:image:width', 'og:image:height'];
const allowedNameList = ['twitter:card', 'twitter:site', 'twitter:image'];

const DefaultMeta = ({ data: { homepage } }: Props) => {
  const meta = homepage.yoastMeta.filter((m) => {
    if (
      (m.property && allowedPropertyList.includes(m.property))
      || (m.name && allowedNameList.includes(m.name))
    ) {
      return true;
    }

    return false;
  });

  return <Helmet meta={normalizeMeta(meta)} />;
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        homepage: wordpressPage(path: {eq: "/"}) {
          yoastMeta: yoast_meta {
            name
            property
            content
          }
        }
      }
    `}
    render={(data) => <DefaultMeta data={data} />}
  />
);
