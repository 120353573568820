// @flow
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import titleize from 'titleize';

import { paths } from '../../config';
import Layout from '../layouts/DefaultLayout';
import DefaultMeta from './DefaultMeta';
import SEO from './SEO';
import PostListItem from './PostListItem';
import Pagination from './Pagination';
import TaxonomySelect from './TaxonomySelect';
import { PageHeader, IntroText } from '../styles';

const AuthorLabel = styled.span`
  display: block;
  font-size: 0.6em;
  font-weight: normal;
  font-family: var(--font-family-main);
  color: #7c7c7c;
`;

export type Props = {
  pageContext: {
    basePath: string,
    title: string,
    introText: string,
    canonical: string,
    numPages: number,
    currentPage: number,
    tag: string,
    category: string,
    author: string,
  },
  data: {
    posts: {
      edges: Array<{
        node: {
          id: string,
          title: string,
          path: string,
          excerpt: string,
          type: string,
          featuredImage?: {
            localFile?: {
              childImageSharp: {
                fluid: any,
              },
            },
            alt: string,
          },
          tags?: Array<{
            path: string,
            name: string,
          }>,
          customMeta: {
            commentCount: number,
          },
        },
      }>,
    },
    allTags: {
      edges: Array<{
        node: {
          id: string,
          path: string,
          name: string,
        },
      }>,
    },
    allCategories: {
      edges: Array<{
        node: {
          id: string,
          path: string,
          name: string,
        },
      }>,
    },
  },
};

const PostArchive = (props: Props) => {
  const {
    pageContext: {
      basePath,
      title,
      introText,
      canonical,
      numPages,
      currentPage,
      tag: currentTagId,
      category: currentCatId,
      author: currentAuthorId,
    },
    data: {
      posts,
      allTags,
      allCategories,
    },
  } = props;

  const taxonomies = currentCatId ? allCategories : allTags;
  const currentTaxId = currentCatId || currentTagId || 'everything';
  const isAuthor = !!currentAuthorId;

  const pageTitle = `${titleize(title)} Archives - ethical.net`;

  return (
    <Layout>
      <DefaultMeta />
      <SEO
        title={pageTitle}
        canonical={canonical}
        meta={[{
          property: 'og:title',
          content: pageTitle,
        }, {
          property: 'og:url',
          content: canonical,
        }]}
      />

      <PageHeader>
        <h1>
          {isAuthor && <AuthorLabel>Author Archive</AuthorLabel>}
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: titleize(title) }} />
        </h1>
        <IntroText dangerouslySetInnerHTML={{ __html: introText }} />
      </PageHeader>

      {!isAuthor && (
        <TaxonomySelect
          taxonomies={taxonomies.edges.map(({ node }) => node)}
          currentTaxId={currentTaxId}
          basePath={paths.blog}
        />
      )}

      <div className="row">
        {posts.edges.map((edge) => (
          <div key={edge.node.id} className="col-sm-6 col-md-4 col-lg-3">
            <PostListItem item={edge.node} />
          </div>
        ))}
      </div>

      {numPages > 1 && (
        <Pagination
          basePath={basePath}
          numPages={numPages}
          currentPage={currentPage}
        />
      )}
    </Layout>
  );
};

export default PostArchive;

export const featuredMediaFragment = graphql`
  fragment FeaturedMedia on wordpress__wp_media {
    alt: alt_text
    localFile {
      childImageSharp {
        fluid(maxWidth: 308) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export const wordPressPostConnectionFragment = graphql`
  fragment WordPressPostConnection on wordpress__POSTConnection {
    edges {
      node {
        id
        title
        path
        excerpt
        type
        featuredImage: featured_media {
          ...FeaturedMedia
        }
        tags {
          path
          name
        }
        categories {
          path
          name
        }
        customMeta: et_custom_meta {
          commentCount: comment_count
        }
      }
    }
  }
`;
