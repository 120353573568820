/**
 * Renders a collapsible taxonomy terms (category, tag etc) list
 *
 * @flow
 */
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';

import Link from './Link';
import { UnderlinedToggleButton } from './ToggleButton';

const Wrapper = styled.div`
  margin-bottom: 23px;
  overflow: hidden;

  @media (min-width: 768px) {
    margin-bottom: 39px;
  }

  @media (min-width: 992px) {
    margin-bottom: 48px;
  }
`;

const Selector = styled.div`
  color: #8e8e8e;
  font-size: 17px;

  > span {
    white-space: nowrap;
    vertical-align: middle;
  }

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const Taxonomies = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding-top: 20px;

  @media (min-width: 768px) {
    margin: 0 -15px;
    padding-top: 40px;
  }

  @media (min-width: 992px) {
    margin: 0 -25px;
  }
`;

const Taxonomy = styled.div`
  margin: 5px 10px;
  white-space: nowrap;

  @media (min-width: 768px) {
    margin: 8px 15px;
    font-size: 20px;
  }

  @media (min-width: 992px) {
    margin: 8px 25px;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => (props.current ? 'var(--color-link)' : '#9b9b9b')};
  font-size: 14px;

  &:focus,
  &:hover {
    color: #000;
  }

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

type Props = {
  taxonomies: Array<{
    id: string,
    path: string,
    name: string,
  }>,
  currentTaxId: string,
  basePath: string,
};

const TaxonomySelect = ({ taxonomies, currentTaxId, basePath }: Props) => {
  const [active, setActive] = useState(false);

  const toggleClick = useCallback(() => {
    setActive(!active);
  }, [active]);

  const currentTaxonomy = taxonomies.find((tax) => tax.id === currentTaxId);
  const toggleButtonText = currentTaxonomy ? currentTaxonomy.name : 'Everything';

  return (
    <Wrapper>
      <Selector>
        <span>Show me </span>
        <UnderlinedToggleButton
          active={active}
          onClick={toggleClick}
        >
          <span>{toggleButtonText}</span>
        </UnderlinedToggleButton>
      </Selector>
      <AnimateHeight
        duration={500}
        animateOpacity
        height={active ? 'auto' : 0}
      >
        <Taxonomies>
          <Taxonomy key="everything">
            <StyledLink
              to={basePath}
              current={currentTaxId === 'everything' ? 1 : 0}
            >
              Everything
            </StyledLink>
          </Taxonomy>
          {taxonomies.map((tax) => (
            <Taxonomy key={tax.id}>
              <StyledLink
                to={tax.path}
                current={currentTaxId === tax.id ? 1 : 0}
              >
                {tax.name}
              </StyledLink>
            </Taxonomy>
          ))}
        </Taxonomies>
      </AnimateHeight>
    </Wrapper>
  );
};

export default TaxonomySelect;
